// src/aws-exports.js
const awsConfig = {
  aws_project_region: "ap-southeast-1",
  aws_cognito_region: "ap-southeast-1",

  aws_user_pools_id: "ap-southeast-1_e1Dm1gdMD",
  //aws_user_pools_id: "ap-southeast-1_NsUvTsVeH",


  aws_user_pools_web_client_id: "6pqeu969nr2ksmonhgrg8o7h65",
  //aws_user_pools_web_client_id: "32r2428v6pt3hh1vgv1t82ain2",


  oauth: {
    domain: "nets-stip.auth.ap-southeast-1.amazoncognito.com",
    //domain: "nets-card.auth.ap-southeast-1.amazoncognito.com",


     scope: [
    "openid",
    "email",
    "profile",
    "aws.cognito.signin.user.admin" // Add this scope
  ],
    redirectSignIn: "https://d34t3z15nppx0w.cloudfront.net/",
    redirectSignOut: "https://d34t3z15nppx0w.cloudfront.net/",
    //redirectSignIn: "http://localhost:3000/",
    //redirectSignOut: "https://localhost:3000/",
    responseType: 'token',
  }
};

export default awsConfig;
