import { Backdrop, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';

import { UserProvider } from './UserContext';
import AuthWrapper from './AuthWrapper';
import { initializeSessionManagement, cleanupSessionManagement } from './sessionManager';

import CardForm from './components/cards/CardForm';
import Home from './components/home/Home';
import Navbar from './components/misc/Navbar';
import Profile from './components/misc/Profile';
import AuthorizationLimits from './components/AuthorizationLimits';
import Reports from './components/Reports';
import StandIn from './components/StandIn';
import ExistingCard from './components/cards/ExistingCard';

Amplify.configure(awsConfig);

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Initialize session management and set loading state
    initializeSessionManagement();
    setLoading(false);  // Stop loading once session management is initialized

    return () => {
      cleanupSessionManagement(); // Clean up listeners when the app unmounts
    };
  }, []);

  if (loading) {
    // Show loading screen while the app is checking authentication
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          Checking authentication...
        </Typography>
      </Backdrop>
    );
  }

  return (
    <UserProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<AuthWrapper><Home /></AuthWrapper>} />
          <Route path="/home" element={<AuthWrapper><Home /></AuthWrapper>} />
          <Route path="/add-card" element={<AuthWrapper><CardForm /></AuthWrapper>} />
          <Route path="/profile" element={<AuthWrapper><Profile /></AuthWrapper>} />
          <Route path="*" element={<AuthWrapper><Home /></AuthWrapper>} />
          <Route path="/stand-in" element={<AuthWrapper><StandIn /></AuthWrapper>} />
          <Route path="/authorization-limits" element={<AuthWrapper><AuthorizationLimits /></AuthWrapper>} />
          <Route path="/new-card" element={<AuthWrapper><CardForm /></AuthWrapper>} />
          <Route path="/existing-card" element={<AuthWrapper><ExistingCard /></AuthWrapper>} />
          <Route path="/reports" element={<AuthWrapper><Reports /></AuthWrapper>} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
