import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const VIPList = forwardRef((props, ref) => {
  const initialFormData = {
    cardNumber: '',
    cardSequenceNumber: '',
    effectiveDate: null,
    expiryDate: null,
    dailyAmountLimit: '',
    transactionAmountLimit: '',
    dailyCountLimit: '',
    transactionLimitDate: null,
    instrumentType: 'P2', // Static value
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (validateForm()) {
        // Constructing the payload
        const payload = {
          header: {
            apiVersion: "3.0.0",
            srcSysId: "NETS123456",
            srcSysRef: "20241007123045001",
            pwd: "EncryptedPassword"
          },
          body: {
            cardNumber: formData.cardNumber,
            cardSequenceNumber: formData.cardSequenceNumber,
            effectiveDate: formData.effectiveDate,
            expiryDate: formData.expiryDate,
            dailyAmountLimit: formData.dailyAmountLimit,
            transactionAmountLimit: formData.transactionAmountLimit,
            dailyCountLimit: formData.dailyCountLimit,
            transactionLimitDate: formData.transactionLimitDate,
            instrumentType: formData.instrumentType,
          },
          trailer: {
            srcSysDt: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
            signature: "DigitalSignatureHere"
          }
        };

        // Log the submission payload
        console.log('Submission Payload:', payload);

        // Call the onSuccess prop with a success message
        if (props.onSuccess && typeof props.onSuccess === 'function') {
          props.onSuccess('Card submitted successfully!'); // Show success message
        }
        return true; // Return true if the form is valid
      }
      return false; // Return false if there are validation errors
    },
    resetForm: () => {
      setFormData(initialFormData); // Reset form data to initial state
      setErrors({}); // Clear any errors
    },
  }));

  const validateForm = () => {
    const validationErrors = {};

    // Card Number validation
    if (!/^\d{15,19}$/.test(formData.cardNumber)) {
      validationErrors.cardNumber = 'Card number must be between 15 and 19 digits and numeric.';
    }

    // Card Sequence Number validation
    if (!/^\d{3}$/.test(formData.cardSequenceNumber)) {
      validationErrors.cardSequenceNumber = 'Card sequence number must be exactly 3 digits.';
    }

    // Effective Date validation
    if (!formData.effectiveDate || !dayjs(formData.effectiveDate).isValid()) {
      validationErrors.effectiveDate = 'Effective date is required.';
    }

    // Card Expiry Date validation
    if (!formData.expiryDate || !dayjs(formData.expiryDate).isValid()) {
      validationErrors.expiryDate = 'Card expiry date is required.';
    }

    // Daily Amount Limit validation
    if (formData.dailyAmountLimit < 0 || formData.dailyAmountLimit > 500) {
      validationErrors.dailyAmountLimit = 'Daily accumulated amount limit must be between 0 and 500.';
    }

    // Transaction Amount Limit validation
    if (formData.transactionAmountLimit < 0 || formData.transactionAmountLimit > 500) {
      validationErrors.transactionAmountLimit = 'Transaction amount limit must be between 0 and 500.';
    }

    // Daily Count Limit validation
    if (formData.dailyCountLimit < 0 || formData.dailyCountLimit > 999) {
      validationErrors.dailyCountLimit = 'Daily accumulated count limit must be between 0 and 999.';
    }

    // Transaction Limit Date validation
    if (!formData.transactionLimitDate) {
      validationErrors.transactionLimitDate = 'Transaction limit expiration date is required.';
    }

    // Instrument Type validation
    if (!formData.instrumentType) {
      validationErrors.instrumentType = 'Instrument type is required.';
    }

    setErrors(validationErrors);

    // Return true if no errors, otherwise return false
    return Object.keys(validationErrors).length === 0;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Card Number"
            name="cardNumber"
            required
            variant="outlined"
            value={formData.cardNumber}
            onChange={handleChange}
            error={!!errors.cardNumber}
            helperText={errors.cardNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Card Sequence Number"
            name="cardSequenceNumber"
            required
            variant="outlined"
            value={formData.cardSequenceNumber}
            onChange={handleChange}
            error={!!errors.cardSequenceNumber}
            helperText={errors.cardSequenceNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Effective Date"
            views={['year', 'month', 'day']}
            value={formData.effectiveDate}
            onChange={(newValue) => setFormData({ ...formData, effectiveDate: newValue })}
            shouldDisableDate={(date) => date.isBefore(dayjs(), 'day')} // Disable past dates
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.effectiveDate} helperText={errors.effectiveDate} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Card Expiry Date"
            views={['year', 'month']}
            value={formData.expiryDate}
            onChange={(newValue) => setFormData({ ...formData, expiryDate: newValue })}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.expiryDate} helperText={errors.expiryDate} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Daily Accumulated Amount Limit"
            name="dailyAmountLimit"
            placeholder="Enter amount"
            required
            variant="outlined"
            value={formData.dailyAmountLimit}
            onChange={handleChange}
            error={!!errors.dailyAmountLimit}
            helperText={errors.dailyAmountLimit}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Accumulated Transaction Amount Limit"
            name="transactionAmountLimit"
            placeholder="Enter transaction amount"
            required
            variant="outlined"
            value={formData.transactionAmountLimit}
            onChange={handleChange}
            error={!!errors.transactionAmountLimit}
            helperText={errors.transactionAmountLimit}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Daily Accumulated Count Limit"
            name="dailyCountLimit"
            placeholder="Enter count"
            required
            variant="outlined"
            value={formData.dailyCountLimit}
            onChange={handleChange}
            error={!!errors.dailyCountLimit}
            helperText={errors.dailyCountLimit}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Accumulated Transaction Amount Limit Expiration Date"
            value={formData.transactionLimitDate}
            onChange={(newValue) => setFormData({ ...formData, transactionLimitDate: newValue })}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.transactionLimitDate} helperText={errors.transactionLimitDate} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Instrument Type"
            name="instrumentType"
            required
            variant="outlined"
            value="P2" // Static value
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
});

export default VIPList;
