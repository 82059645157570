import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Grid, TextField, Typography, Box, FormControl, RadioGroup, FormControlLabel, Radio, styled, Switch } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 6,
  },
}));

const BlockContainer = styled(Box)(({ theme }) => ({
  border: '1px solid #007BFF',
  borderRadius: 6,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  position: 'relative',
}));

const AttachedLabel = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '-10px',
  left: '12px',
  backgroundColor: '#fff',
  padding: '0 4px',
  fontSize: '14px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

const NegativeList = forwardRef((props, ref) => {
  const initialFormData = {
    cardNumber: '',
    cardSequenceNumber: '',
    expiryDate: null,
    instrumentType: 'P1', // Static value
    instrumentStatus: '60', // Default value
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [luhnCheckEnabled, setLuhnCheckEnabled] = useState(false); // State for Luhn check toggle

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (validateFields()) {
        // Constructing the payload
        const payload = {
          header: {
            apiVersion: "3.0.0",
            srcSysId: "NETS123456",
            srcSysRef: "20241007123045001",
            pwd: "EncryptedPassword"
          },
          body: {
            cardNumber: formData.cardNumber,
            cardSequenceNumber: formData.cardSequenceNumber,
            expiryDate: formData.expiryDate,
            instrumentType: formData.instrumentType,
            instrumentStatus: formData.instrumentStatus,
          },
          trailer: {
            srcSysDt: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
            signature: "DigitalSignatureHere"
          }
        };

        // Log the submission payload
        console.log('Submission Payload:', payload);

        // Call the onSuccess prop with a success message
        if (props.onSuccess && typeof props.onSuccess === 'function') {
          props.onSuccess('Card submitted successfully!'); // Show success message
        }
        return true; // Return true if the form is valid
      }
      return false; // Return false if there are validation errors
    },
    resetForm: () => {
      setFormData(initialFormData); // Reset form data to initial state
      setErrors({}); // Clear any errors
      setLuhnCheckEnabled(false); // Reset Luhn check toggle
    }
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!/^\d{15,19}$/.test(formData.cardNumber)) {
      newErrors.cardNumber = 'Card number must be between 15 and 19 digits and numeric.';
    } else if (luhnCheckEnabled && !isValidLuhn(formData.cardNumber)) {
      newErrors.cardNumber = 'Card number failed the Luhn check.';
    }
    
    if (!/^\d{3}$/.test(formData.cardSequenceNumber)) {
      newErrors.cardSequenceNumber = 'Card sequence number must be exactly 3 digits.';
    }
    
    if (!formData.expiryDate) {
      newErrors.expiryDate = 'Card expiry date is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValidLuhn = (number) => {
    let sum = 0;
    let shouldDouble = false;
    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number.charAt(i), 10);
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }
    return sum % 10 === 0;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <CustomTextField
            fullWidth
            label="Card Number"
            name="cardNumber"
            required
            variant="outlined"
            value={formData.cardNumber}
            onChange={handleChange}
            error={!!errors.cardNumber}
            helperText={errors.cardNumber}
          />
        </Grid>
        <Grid item xs={12} md={3} display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={luhnCheckEnabled}
                onChange={(e) => setLuhnCheckEnabled(e.target.checked)}
                color="primary"
              />
            }
            label="Luhn Check"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            fullWidth
            label="Card Sequence Number"
            name="cardSequenceNumber"
            required
            variant="outlined"
            value={formData.cardSequenceNumber}
            onChange={handleChange}
            error={!!errors.cardSequenceNumber}
            helperText={errors.cardSequenceNumber}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Card Expiry Date"
            views={['year', 'month']}
            value={formData.expiryDate}
            onChange={(newValue) => setFormData({ ...formData, expiryDate: newValue })}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.expiryDate} helperText={errors.expiryDate} />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <BlockContainer>
            <AttachedLabel>Instrument Status</AttachedLabel>
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                name="instrumentStatus"
                value={formData.instrumentStatus}
                onChange={handleChange}
                row
              >
                <FormControlLabel value="60" control={<Radio />} label="Lost (60)" />
                <FormControlLabel value="70" control={<Radio />} label="Stolen (70)" />
                <FormControlLabel value="80" control={<Radio />} label="Closed (80)" />
                <FormControlLabel value="20" control={<Radio />} label="Retriable for LTA ABT (20)" />
              </RadioGroup>
            </FormControl>
          </BlockContainer>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            fullWidth
            label="Instrument Type"
            name="instrumentType"
            variant="outlined"
            value="P1" // Static value
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
});

export default NegativeList;
