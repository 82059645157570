import React, { useEffect, useState } from 'react';
import { Container, Typography, TextField, Box, Paper, Grid, Avatar, Card, CardContent } from '@mui/material';
import { CognitoIdentityProviderClient, GetUserCommand } from "@aws-sdk/client-cognito-identity-provider";
import { Auth } from 'aws-amplify';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(5),
  display: 'flex',
  justifyContent: 'center',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: '2rem',
  borderRadius: '12px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff',
  width: '100%',
  maxWidth: '600px',
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '50%',
    background: 'linear-gradient(135deg, #3f51b5 0%, #3f51b5 70%, transparent 100%)',
    borderRadius: '12px 12px 0 0',
    zIndex: -1,
  },
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: '#ffffff',
  marginBottom: theme.spacing(2),
}));

const UserInfo = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
}));

const IconTextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  padding: '0.5rem',
  border: '1px solid #e0e0e0',
  borderRadius: '8px',
  backgroundColor: '#f5f6fa',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#e3f2fd',
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '100px',
  height: '100px',
  margin: '0 auto',
  backgroundColor: '#ffffff',
  border: '3px solid #3f51b5',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
}));

const Profile = () => {
  const [userAttributes, setUserAttributes] = useState({});

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        await retrieveUserAttributes(accessToken);
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      }
    };

    fetchUserAttributes();
  }, []);

  const retrieveUserAttributes = async (accessToken) => {
    const client = new CognitoIdentityProviderClient({ region: 'ap-southeast-1' });

    try {
      const userCommand = new GetUserCommand({ AccessToken: accessToken });
      const response = await client.send(userCommand);

      const attributes = {};
      response.UserAttributes.forEach(attr => {
        if (attr.Name === 'email') {
          attributes.email = attr.Value;
        } else if (attr.Name === 'phone_number') {
          attributes.phoneNumber = attr.Value;
        } else if (attr.Name.startsWith('custom:')) {
          attributes[attr.Name] = attr.Value;
        }
      });

      setUserAttributes(prevAttributes => ({
        ...prevAttributes,
        ...attributes
      }));
    } catch (error) {
      console.error('Error retrieving user attributes', error);
    }
  };

  return (
    <StyledContainer>
      <StyledCard elevation={4}>
        <HeaderBox>
          <Typography variant="h4">Profile Information</Typography>
          <StyledAvatar>
            <AccountCircleIcon sx={{ fontSize: 60, color: '#3f51b5' }} />
          </StyledAvatar>
        </HeaderBox>
        <UserInfo>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconTextBox>
                <PhoneIcon sx={{ color: '#3f51b5', marginRight: 1 }} />
                <Typography variant="body1">
                  Phone: {userAttributes.phoneNumber || 'N/A'}
                </Typography>
              </IconTextBox>
            </Grid>
            <Grid item xs={12}>
              <IconTextBox>
                <EmailIcon sx={{ color: '#3f51b5', marginRight: 1 }} />
                <Typography variant="body1">
                  Email: {userAttributes.email || 'N/A'}
                </Typography>
              </IconTextBox>
            </Grid>
            <Grid item xs={12}>
              <IconTextBox>
                <BusinessIcon sx={{ color: '#3f51b5', marginRight: 1 }} />
                <Typography variant="body1">
                  Bank ID: {userAttributes['custom:bank_id'] || 'N/A'}
                </Typography>
              </IconTextBox>
            </Grid>
            <Grid item xs={12}>
              <IconTextBox>
                <BusinessIcon sx={{ color: '#3f51b5', marginRight: 1 }} />
                <Typography variant="body1">
                  Bank Name: {userAttributes['custom:bank_name'] || 'N/A'}
                </Typography>
              </IconTextBox>
            </Grid>
          </Grid>
        </UserInfo>
      </StyledCard>
    </StyledContainer>
  );
};

export default Profile;
