import React, { useState, useRef, useEffect } from 'react'; 
import { Tabs, Tab, Container, Card, CardContent, Box, Button, Snackbar, Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'; // Icon representing submit action
import NegativeList from './NegativeList';
import PositiveList from './PositiveList';
import VIPList from './VIPList';
import { styled } from '@mui/system';
import { Auth } from 'aws-amplify'; // Import Auth to get user attributes

// Custom styled submit button for a more professional appearance
const StyledSubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#007BFF', // Soft blue color
  borderRadius: 6,             // Slightly rounded corners for a clean look
  color: 'white',
  height: 48,
  padding: '0 30px',
  fontWeight: 'bold',
  fontSize: '16px',
  textTransform: 'uppercase',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Simple shadow for depth
  '&:hover': {
    backgroundColor: '#0056b3', // Darker blue on hover for contrast
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Slightly more pronounced shadow on hover
  },
}));

// Custom styled header for a professional look
const StyledHeader = styled('div')(({ theme }) => ({
  fontSize: '28px',
  fontWeight: 'bold',
  color: '#003366',
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  textTransform: 'uppercase',
  letterSpacing: '2px',
}));

// Custom Card for a clean, professional container
const GlassCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slightly opaque white for a clean look
  borderRadius: '10px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(4),
  width: '1000px', // Adjust width as needed
  margin: 'auto',
}));

const CardForm = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [bankName, setBankName] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // Success message state
  const [submissionPayload, setSubmissionPayload] = useState(null); // To hold submission payload
  const negativeListRef = useRef();
  const positiveListRef = useRef();
  const vipListRef = useRef();

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = user.attributes;

        if (attributes['custom:bank_name']) {
          const bankNameValue = attributes['custom:bank_name'].toLowerCase();
          setBankName(bankNameValue);

          switch (bankNameValue) {
            case 'dbs':
              setSelectedOption('Negative');
              break;
            case 'uob':
              setSelectedOption('VIP');
              break;
            case 'ocbc':
              setSelectedOption('Positive');
              break;
            default:
              setSelectedOption('');
          }
        }
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      }
    };

    fetchUserAttributes();
  }, []);

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message); // Set the success message state
  };

  const handleOptionChange = (event, newOption) => {
    setSelectedOption(newOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true; // Assume valid until proven otherwise
    let payload = null; // To hold the submission payload

    // Perform validation based on the selected option
    if (selectedOption === 'Negative') {
      payload = negativeListRef.current.submitForm();
      isValid = !!payload; // Check if payload is not null or undefined
    } else if (selectedOption === 'Positive') {
      payload = positiveListRef.current.submitForm();
      isValid = !!payload; // Check if payload is not null or undefined
    } else if (selectedOption === 'VIP') {
      payload = vipListRef.current.submitForm();
      isValid = !!payload; // Check if payload is not null or undefined
    }

    // Only show success message if the form is valid
    if (isValid) {
      console.log('Submission Payload:', payload); // Log the submission payload
      setSuccessMessage('Card submitted successfully!');

      // Reset form after delay
      setTimeout(() => {
        setSuccessMessage('');
        // Reset form logic
        if (selectedOption === 'Negative') {
          negativeListRef.current.resetForm();
        } else if (selectedOption === 'Positive') {
          positiveListRef.current.resetForm();
        } else if (selectedOption === 'VIP') {
          vipListRef.current.resetForm();
        }
      }, 3000); // Display message for 3 seconds
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <GlassCard elevation={3}>
        <CardContent>
          <StyledHeader>Card Information Update</StyledHeader>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
            <Tabs
              value={selectedOption}
              onChange={handleOptionChange}
              variant="fullWidth"
              centered
            >
              {bankName === 'dbs' && <Tab label="Negative List" value="Negative" />}
              {bankName === 'uob' && <Tab label="VIP List" value="VIP" />}
              {bankName === 'uob' && <Tab label="Negative List" value="Negative" />}
              {bankName === 'ocbc' && <Tab label="Positive List" value="Positive" />}
              {bankName === 'ocbc' && <Tab label="VIP List" value="VIP" />}
            </Tabs>
          </Box>
          <form onSubmit={handleSubmit}>
            {selectedOption === 'Negative' && <NegativeList ref={negativeListRef} onSuccess={handleSuccessMessage} />}
            {selectedOption === 'Positive' && <PositiveList ref={positiveListRef} onSuccess={handleSuccessMessage} />}
            {selectedOption === 'VIP' && <VIPList ref={vipListRef} onSuccess={handleSuccessMessage} />}
            <Box textAlign="center" sx={{ mt: 4 }}>
              <StyledSubmitButton variant="contained" type="submit">
                <CheckIcon sx={{ mr: 1 }} />
                Submit
              </StyledSubmitButton>
            </Box>
          </form>
          <Snackbar open={!!successMessage} autoHideDuration={6000}>
            <Alert severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          </Snackbar>
        </CardContent>
      </GlassCard>
    </Container>
  );
};

export default CardForm;
