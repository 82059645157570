import { Auth, Hub, Cache } from 'aws-amplify';
import awsConfig from './aws-exports';

// Cognito login URL
const cognitoDomain = awsConfig.oauth.domain;
const clientId = awsConfig.aws_user_pools_web_client_id;
const redirectSignIn = awsConfig.oauth.redirectSignIn;
const loginUrl = `https://${cognitoDomain}/login?client_id=${clientId}&response_type=token&redirect_uri=${redirectSignIn}`;

const redirectToLogin = () => {
    clearCache();
    window.location.href = loginUrl;  // Redirect to the login page if the session is invalid
};

const clearCache = () => {
    localStorage.clear();
    sessionStorage.clear();
    Cache.clear();
    if (window.caches) {
        window.caches.keys().then(cacheNames => {
            cacheNames.forEach(cache => window.caches.delete(cache));
        });
    }
};

// Check the session validity
const checkSession = async () => {
    try {
        const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        if (!currentUser) {
            console.error("No authenticated user found.");
            redirectToLogin();
            return false;
        }

        const userSession = currentUser.signInUserSession;
        if (userSession.isValid()) {
            return true;
        }

        // If session is invalid, try to refresh the session
        await Auth.currentSession();
        return true;
    } catch (error) {
        console.error("Session check failed:", error);
        redirectToLogin();
        return false;
    }
};

// Fetch user data (tokens and attributes)
const getUserData = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        const tokens = user.signInUserSession;
        const attributes = await Auth.userAttributes(user);
        const userAttributes = {};
        attributes.forEach(attr => {
            userAttributes[attr.Name] = attr.Value;
        });
        return { userId: user.username, tokens, attributes: userAttributes };
    } catch (error) {
        console.error("Error retrieving user data:", error);
        redirectToLogin();
        return null;
    }
};

// Initialize the authentication listener (for session events like signOut)
const initAuthListener = () => {
    Hub.listen('auth', (data) => {
        const { event } = data.payload;
        if (event === 'signOut' || event === 'tokenRefresh_failure') {
            redirectToLogin(); // If session expired or signed out, redirect
        }
    });
};

// Check session on user activity (debounced)
let lastCheck = 0;

const handleUserActivity = async () => {
    const now = Date.now();
    if (now - lastCheck > 2000) { // Check every 2 seconds
        lastCheck = now;
        const isValid = await checkSession();
        if (!isValid) {
            redirectToLogin();
            window.location.reload();
        }
    }
};

// Add activity listeners to monitor session validity on user actions
const addGlobalActivityListeners = () => {
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('mousemove', handleUserActivity);
};

// Remove activity listeners on cleanup
const removeGlobalActivityListeners = () => {
    window.removeEventListener('click', handleUserActivity);
    window.removeEventListener('keypress', handleUserActivity);
    window.removeEventListener('mousemove', handleUserActivity);
};

// Initialize session management when the app loads
const initializeSessionManagement = () => {
    addGlobalActivityListeners();
    initAuthListener();
};

// Clean up listeners when the app unmounts
const cleanupSessionManagement = () => {
    removeGlobalActivityListeners();
};

export {
    checkSession,
    clearCache,
    initAuthListener,
    redirectToLogin,
    getUserData,
    initializeSessionManagement,
    cleanupSessionManagement,
};
