import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserData } from './sessionManager'; // Importing getUserData from sessionManager

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const userData = await getUserData();
            if (userData) {
                setUser(userData); // Set the user data in state
            }
        };
        fetchUserData();
    }, []);

    return (
        <UserContext.Provider value={{ user }}>
            {children}
        </UserContext.Provider>
    );
};
