// src/components/Reports.js
import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
  Box,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const Reports = () => {
  const handleDownload = (reportName) => {
    // Logic to download the report
    alert(`Downloading ${reportName}...`);
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 5 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
        Reports
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
        View and download your reports below.
      </Typography>

      <Grid container spacing={4}>
        {/* Placeholder for Reports - can be dynamically generated */}
        {[1, 2, 3].map((report) => ( // Use a loop to represent dynamic reports
          <Grid item xs={12} sm={6} md={4} key={report}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '12px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ bgcolor: '#e3f2fd', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                  <CloudDownloadIcon sx={{ fontSize: '40px', color: '#2196f3' }} />
                </Box>
                <Typography variant="h6" component="div" gutterBottom>
                  Report #{report}  {/* Placeholder title */}
                </Typography>
                <Typography variant="body2" color="textSecondary" textAlign="center" sx={{ mb: 3 }}>
                  Click the button below to download this report.  {/* Placeholder description */}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <Button
                  size="large"
                  color="primary"
                  onClick={() => handleDownload(`Report ${report}`)} // Placeholder download logic
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  sx={{ borderRadius: '20px' }}
                >
                  Download
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Reports;
