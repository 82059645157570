import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const PositiveList = forwardRef((props, ref) => {
  const initialFormData = {
    cardNumber: '',
    cardSequenceNumber: '',
    effectiveDate: null,
    expiryDate: null,
    instrumentType: 'P1', // Static value
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (validateForm()) {
        // Constructing the payload
        const payload = {
          header: {
            apiVersion: "3.0.0",
            srcSysId: "NETS123456",
            srcSysRef: "20241007123045001",
            pwd: "EncryptedPassword"
          },
          body: {
            cardNumber: formData.cardNumber,
            cardSequenceNumber: formData.cardSequenceNumber,
            effectiveDate: formData.effectiveDate,
            expiryDate: formData.expiryDate,
            instrumentType: formData.instrumentType,
          },
          trailer: {
            srcSysDt: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
            signature: "DigitalSignatureHere"
          }
        };

        // Log the submission payload
        console.log('Submission Payload:', payload);

        // Call the onSuccess prop with a success message
        if (props.onSuccess && typeof props.onSuccess === 'function') {
          props.onSuccess('Card submitted successfully!'); // Show success message
        }
        return true; // Return true if the form is valid
      }
      return false; // Return false if there are validation errors
    },
    resetForm: () => {
      setFormData(initialFormData); // Reset form data to initial state
      setErrors({}); // Clear any errors
    },
  }));

  const validateForm = () => {
    const validationErrors = {};

    // Card Number validation
    if (!/^\d{15,19}$/.test(formData.cardNumber)) {
      validationErrors.cardNumber = 'Card number must be between 15 and 19 digits and numeric.';
    }

    // Card Sequence Number validation
    if (!/^\d{3}$/.test(formData.cardSequenceNumber)) {
      validationErrors.cardSequenceNumber = 'Card sequence number must be exactly 3 digits.';
    }

    // Effective Date validation
    if (!formData.effectiveDate || !dayjs(formData.effectiveDate).isValid()) {
      validationErrors.effectiveDate = 'Please select a valid effective date.';
    }

    // Card Expiry Date validation
    if (!formData.expiryDate || !dayjs(formData.expiryDate).isValid()) {
      validationErrors.expiryDate = 'Please select a valid expiry date.';
    }

    // Instrument Type validation
    if (!formData.instrumentType) {
      validationErrors.instrumentType = 'Instrument type is required.';
    }

    setErrors(validationErrors);

    // Return true if no errors, otherwise return false
    return Object.keys(validationErrors).length === 0;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Card Number"
            name="cardNumber"
            required
            variant="outlined"
            value={formData.cardNumber}
            onChange={handleChange}
            error={!!errors.cardNumber}
            helperText={errors.cardNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Card Sequence Number"
            name="cardSequenceNumber"
            required
            variant="outlined"
            value={formData.cardSequenceNumber}
            onChange={handleChange}
            error={!!errors.cardSequenceNumber}
            helperText={errors.cardSequenceNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Effective Date"
            views={['year', 'month', 'day']}
            value={formData.effectiveDate}
            onChange={(newValue) => setFormData({ ...formData, effectiveDate: newValue })}
            shouldDisableDate={(date) => date.isBefore(dayjs(), 'day')} // Disable past dates
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.effectiveDate} helperText={errors.effectiveDate} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            className="fullWidth"
            label="Card Expiry Date"
            views={['year', 'month']}
            value={formData.expiryDate}
            onChange={(newValue) => setFormData({ ...formData, expiryDate: newValue })}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!errors.expiryDate} helperText={errors.expiryDate} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Instrument Type"
            name="instrumentType"
            required
            variant="outlined"
            value="P1" // Static value
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
});

export default PositiveList;
