import React, { useState } from 'react';
import { Button, TextField, Grid, Container, Card, CardContent, Box, Typography, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import CreditCardIcon from '@mui/icons-material/CreditCard'; // Icon for Card Number
import VpnKeyIcon from '@mui/icons-material/VpnKey'; // Icon for Card Sequence Number
import SearchIcon from '@mui/icons-material/Search'; // Icon for the retrieve button

// Styled Button
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#007BFF',
  color: '#fff',
  padding: '12px 24px',
  borderRadius: 8,
  fontWeight: 'bold',
  textTransform: 'none', // Keeps button text as provided (not uppercase)
  fontSize: '16px',
  boxShadow: '0px 4px 10px rgba(0, 123, 255, 0.3)',
  '&:hover': {
    backgroundColor: '#0056b3',
    boxShadow: '0px 6px 14px rgba(0, 123, 255, 0.4)',
  },
}));

// Custom TextField
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 10, // Matching the border-radius
    '& fieldset': {
      borderColor: grey[400],
    },
    '&:hover fieldset': {
      borderColor: '#007BFF', // Matching the focus color
    },
    '&.Mui-focused fieldset': {
      borderColor: '#007BFF', // Blue focus border
    },
  },
}));

// Styled Header
const StyledHeader = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: 'bold',
  color: '#003366',
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  textTransform: 'uppercase',
  letterSpacing: '2px',
}));

// Custom Card for consistent layout and padding
const CustomCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 12,
  boxShadow: '0 6px 18px rgba(0, 0, 0, 0.1)',
  margin: 'auto',
  width: '1000px', // Match width to the PositiveList
  position: 'relative',
  bottom: '100px', // Position the card downwards by 100 pixels
}));

const ExistingCard = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardSequenceNumber, setCardSequenceNumber] = useState('');
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const validationErrors = {};

    // Card Number validation
    if (!/^\d{15,19}$/.test(cardNumber)) {
      validationErrors.cardNumber = 'Card number must be between 15 and 19 digits and numeric.';
    }

    // Card Sequence Number validation
    if (!/^\d{3}$/.test(cardSequenceNumber)) {
      validationErrors.cardSequenceNumber = 'Card sequence number must be exactly 3 digits.';
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0; // Return true if no errors
  };

  const handleRetrieve = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // Add your retrieve logic here
      alert('Retrieve button clicked');
      console.log('Submission Payload:', { cardNumber, cardSequenceNumber });
    }
  };

  return (
    <Container maxWidth="md" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <CustomCard>
        <CardContent>
          {/* Form Title - Styled Header */}
          <StyledHeader variant="h5">
            Existing Card Information
          </StyledHeader>

          {/* Form Box */}
          <Box component="form" onSubmit={handleRetrieve}>
            <Grid container spacing={2}>
              {/* Card Number Input */}
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="Card Number"
                  name="cardNumber"
                  variant="outlined"
                  required
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  error={!!errors.cardNumber}
                  helperText={errors.cardNumber}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CreditCardIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Card Sequence Number Input */}
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  label="Card Sequence Number"
                  name="cardSequenceNumber"
                  variant="outlined"
                  required
                  value={cardSequenceNumber}
                  onChange={(e) => setCardSequenceNumber(e.target.value)}
                  error={!!errors.cardSequenceNumber}
                  helperText={errors.cardSequenceNumber}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Retrieve Button */}
              <Grid item xs={12} textAlign="center">
                <StyledButton type="submit">
                  <SearchIcon sx={{ mr: 1 }} />
                  Retrieve
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </CustomCard>
    </Container>
  );
};

export default ExistingCard;
