import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { checkSession } from './sessionManager'; // Import checkSession

const AuthWrapper = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = React.useState(null);

    useEffect(() => {
        const verifySession = async () => {
            const isValid = await checkSession(); // Validate session
            setIsAuthenticated(isValid);
        };

        verifySession();
    }, []);

    if (isAuthenticated === null) {
        return null; // Show nothing while session is being verified
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />; // Redirect to login page if not authenticated
    }

    return children;
};

export default AuthWrapper;
