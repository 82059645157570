import React from 'react';
import { Container, Box, Typography, Card, CardContent, Divider } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#FFFFFF', // White background for cleanliness
  borderRadius: '10px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#003366', // Dark blue for professional appearance
  marginBottom: theme.spacing(2),
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color: '#555555', // Dark gray for subtle contrast
  marginBottom: theme.spacing(3),
}));

const AuthorizationLimits = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', // Align to the start for moving up
        height: '100vh',
        paddingTop: '50px', // Adjust this value to move the card up
      }}
    >
      <StyledCard>
        <CardContent sx={{ textAlign: 'center' }}>
          <Header>Authorization Limit Update</Header>
          <Divider sx={{ margin: '16px 0' }} />
          <SubHeader>
            Download the form to manage your transaction limits effectively.
          </SubHeader>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default AuthorizationLimits;
